
export const environment = {
  urlImageHapolo: 'carlockprincipal.png',
  googleApiKey: '',
  apiKey: '',
  googleFlag: 0,
  color: '#A3A3A3',
  ip: false,
  apelido: false,
  admin: false,
  allowAccountCreation: false,
  pacote: 'br.com.hapoloapp',
  nomeApp: 'Carlock',
  buildAtual: 30006,
  versaoAtual: '3.0.6',
  chaveOneSignal: 'df970e45-1fc4-45f5-a3b3-148c761348b2',
};

// ** IMAGEM FTP **
// Em arquivo, 1 imagem para a logo por ftp deve ser enviada para a pasta adm
// esta sera usadas no campot urlImageHapolo

// ** SPLASH E ICON **
//npm install @capacitor/assets
//o icon em 1024px precisa ser colocado em uma pasta chamada assets (mesmo caminho do resources)
//npx @capacitor/assets generate

// ** ICONE PERSONALITE PUSH **
//Apos os processos de pasta buildada, caso tenha o iconePersonalizado
//Jogar o SRC do icone de push personalizado (com nome FIXO de ic_stat_logo_personalizado)
// Gerar no site:
//http://romannurik.github.io/AndroidAssetStudio/icons-notification.html#source.type=clipart&source.clipart=ac_unit&source.space.trim=1&source.space.pad=0&name=ic_stat_logo_personalizado


// ** SPLASH PERSONALITE **
// Apenas substituir o gif PROJETO.gif em /src/assets por outro gif de 6 segundos.


// O CODIGO DA VERSAO E VERSAO, São alterados no seguinte caminho:
// android/app/build.gradle
//versionCode 1
//versionName "1.0"
//Trocar pelo conteudo de buildAtual e versaoAtual do enviroment respectivamente
